import {useParams} from "react-router";
import useSWR, {mutate} from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import {Link} from "react-router-dom";
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Select, Flex, Button, Card, Text, Badge, Skeleton, Avatar } from '@radix-ui/themes';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import {get} from "lodash";
import {Heading, Table, TabNav, Dialog, Text, Switch} from "@radix-ui/themes";
import React, {useRef} from "react";
import WorkflowLayout from "~/pages/convert/workflows/WorkflowLayout";
import TextInput from "~/components/form/TextInput";
import * as Form from "@radix-ui/react-form";
import update from "immutability-helper";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { BarChartBigIcon, CloudLightning, CloudLightningIcon, CodeIcon, EditIcon, TextCursorIcon } from "lucide-react";
import AnchorButton from "~/components/AnchorButton";
import { Conversion, GlobalStatsComponent } from "../ConvertHomePage";
import { useLocalStorage } from '~/hooks/useLocalStorage';
import { isEqual } from 'lodash';
import { avatarLetters } from "~/pages/customers/CustomersListPage";

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PAYWALL_BASE_URL = process.env.PLANDALF_PAYWALL_URL;

export function Alert({children}) {
  return (
    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            {children}
          </p>
        </div>
      </div>
    </div>
  )
}

const qualifierMap = {
  findButtonByText: 'button containing'
}
const eventMap = {
  click: 'is clicked',
}
const listenMap = {
  attachment: 'When ',
  custom: 'When custom event'
}

// function GlobalStatsComponent({ stats }) {
//   return (
//     <div className="grid grid-cols-5 gap-4 bg-gray-950 text-white rounded-md h-30 divide-x divide-gray-900">
//       <StatItem label="Unique Views" value={stats?.unique_views || '0'} />
//       <StatItem label="Bounce rate" value={stats?.bounce_rate ? `${stats?.bounce_rate}%` : `-`} />
//       <StatItem label="Conversions" value={stats?.total_conversions || '0'} />
//       <StatItem label="Conversion Rate" value={stats?.conversion_rate ? `${stats.conversion_rate}%` : '-'} />
//       <StatItem label="Total Revenue" value={stats?.total_revenue || '-'} />
//     </div>
//   );
// }

function StatItem({ label, value }) {
  return (
    <div className="flex flex-col items-center space-y-1 p-4 justify-between">
      <div className="text-4xl font-medium">{value}</div>
      <div className="text-xs font-semibold tabular-nums">{label}</div>
    </div>
  );
}

function formatTime(seconds) {
  if (!seconds) return '00:00';
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

// Update the Client interface
interface Client {
  id: number;
  name: string;
  environment: 'live' | 'test';
}

interface Rollout {
  client: Client;
}

interface Workflow {
  id: number;
  name: string;
  rollouts?: Rollout[];
  // Add other workflow properties as needed
}

function DateRangeFilter({ workflow }: { workflow: Workflow }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [range, setRange] = useState('last_30_days');
  const [client, setClient] = useState('');

  const getLiveClient = () => {
    return workflow.rollouts?.find(r => r.client.environment === 'live')?.client.id.toString() || '';
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlClient = params.get('client');
    const liveClient = workflow.rollouts?.find(r => r.client.environment === 'live')?.client.id.toString();
    
    setRange(params.get('range') || 'last_30_days');
    setClient(urlClient || liveClient || '');
    
    if (!urlClient && liveClient) {
      updateFilters(params.get('range') || 'last_30_days', liveClient);
    }
  }, [location, workflow.rollouts]);

  const updateFilters = (newRange: string, newClient: string) => {
    const params = new URLSearchParams();
    params.set('range', newRange);
    if (newClient) params.set('client', newClient);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  return (
    <Flex gap="3" align="center">
      <Select.Root value={range} onValueChange={(value) => updateFilters(value, client)}>
        <Select.Trigger />
        <Select.Content>
          <Select.Item value="last_30_days">Last 30 Days</Select.Item>
          <Select.Item value="last_7_days">Last 7 Days</Select.Item>
          <Select.Item value="today">Today</Select.Item>
          <Select.Item value="yesterday">Yesterday</Select.Item>
          <Select.Item value="this_month">This Month</Select.Item>
          <Select.Item value="last_month">Last Month</Select.Item>
          <Select.Item value="this_year">This Year</Select.Item>
          <Select.Item value="last_year">Last Year</Select.Item>
          <Select.Item value="all_time">All Time</Select.Item>
        </Select.Content>
      </Select.Root>

      <Select.Root 
        value={client}
        onValueChange={(value) => updateFilters(range, value)}>
        <Select.Trigger placeholder="All Clients"/>
        <Select.Content>
          {workflow.rollouts?.map((rollout) => (
            <Select.Item key={rollout.client.id} value={rollout.client.id.toString()}>
              {rollout.client.name}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>

      <Button variant="outline" onClick={() => updateFilters('last_30_days', getLiveClient())}>
        Reset
      </Button>
    </Flex>
  );
}

function LineGraph({ chartData }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 6,
          autoSkip: true,
        },
        grid: {
          display: true,
          color: 'rgba(0, 0, 0, 0.1)',
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };
  
  const data = useMemo(() => {
    if (!chartData || !Array.isArray(chartData) || chartData.length === 0) {
      return {
        labels: [],
        datasets: [
          {
            label: 'Views',
            data: [],
            borderColor: '#49B7D1',
            backgroundColor: '#49B7D1',
            fill: 'start',
            order: 3,
          },
          {
            label: 'Starts',
            data: [],
            borderColor: '#A1DCEB',
            backgroundColor: '#A1DCEB',
            fill: 'start',
            order: 2,
          },
          {
            label: 'Conversions',
            data: [],
            borderColor: '#CCF0FA',
            backgroundColor: '#CCF0FA',
            fill: 'start',
            order: 1,
          },
        ],
      };
    }
    
    // Sort data by date
    const sortedData = [...chartData].sort((a, b) => new Date(a.date) - new Date(b.date));
    
    return {
      labels: sortedData.map(d => d.date),
      datasets: [
        {
          label: 'Views',
          data: sortedData.map(d => d.views),
          borderColor: '#49B7D1',
          backgroundColor: (context) => {
            const value = context.raw;
            return value > 0 ? '#49B7D1' : 'transparent';
          },
          fill: 'start',
          order: 3,
        },
        {
          label: 'Starts',
          data: sortedData.map(d => d.starts),
          borderColor: '#A1DCEB',
          backgroundColor: (context) => {
            const value = context.raw;
            return value > 0 ? '#A1DCEB' : 'transparent';
          },
          fill: 'start',
          order: 2,
        },
        {
          label: 'Conversions',
          data: sortedData.map(d => d.converts),
          borderColor: '#7eb5007a',
          backgroundColor: (context) => {
            const value = context.raw;
            return value > 0 ? '#7EB500' : 'transparent';
          },
          fill: 'start',
          order: 1,
        },
      ],
    };
  }, [chartData]);

  return (
    <div style={{ height: '220px' }}>
      <Line options={options} data={data} />
    </div>
  );
}

function OverviewGridComponent({ workflow }) {
  const location = useLocation();
  const [activeFilters, setActiveFilters] = useState(() => {
    // Initialize from URL params
    const params = new URLSearchParams(location.search);
    const range = params.get('range');
    const client = params.get('client');
    
    // If custom range, get the date values
    const from = params.get('from') || '';
    const to = params.get('to') || '';

    // Check localStorage if no URL params
    const storedFilters = localStorage.getItem('workflow-filters');
    const parsedStoredFilters = storedFilters ? JSON.parse(storedFilters) : null;

    // Use URL params if they exist, otherwise use stored filters, finally fall back to defaults
    return {
      range: range || (parsedStoredFilters?.range) || 'last_30_days',
      client: client || (parsedStoredFilters?.client) || '',
      customRange: range === 'custom' 
        ? { from, to }
        : (parsedStoredFilters?.customRange || { from: '', to: '' })
    };
  });

  // Build query string for stats
  const queryString = useMemo(() => {
    const params = new URLSearchParams({
      range: activeFilters.range,
      ...(activeFilters.client && { client: activeFilters.client }),
      ...(activeFilters.range === 'custom' && {
        from: activeFilters.customRange.from,
        to: activeFilters.customRange.to
      })
    });
    
    // Add timezone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (tz) params.set('tz', tz);
    
    return params.toString();
  }, [activeFilters]);

  const { data: results, error: resultsError } = useSWR(
    `/convert/workflows/${workflow.id}/results${queryString ? `?${queryString}` : ''}`,
    getFetcher
  );

  // Listen for URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const range = params.get('range');
    const client = params.get('client');
    
    if (range || client) {
      setActiveFilters(current => ({
        ...current,
        range: range || current.range,
        client: client || current.client,
        customRange: range === 'custom' 
          ? { 
              from: params.get('from') || current.customRange.from, 
              to: params.get('to') || current.customRange.to 
            }
          : current.customRange
      }));
    }
  }, [location.search]);

  const memoizedChartData = useMemo(() => results?.chart_data, 
    [results?.chart_data], 
    isEqual
  );

  return (
    <>
      <div className="flex gap-4">
        <DateRangeFilter 
          workflow={workflow}
          onFiltersChange={setActiveFilters}
        />
      </div>
      
      <GlobalStatsComponent 
        stats={{ 
          isLoading: !results && !resultsError,
          data: { stats: results?.global_stats }
        }} 
      />

      {/* Add the LineGraph component here */}
      {results?.chart_data && (
        <div className="bg-gray-50 border rounded-lg min-h-[280px] overflow-y-hidden">
          
          <div className="p-4 bg-white">
            <LineGraph chartData={memoizedChartData} />
          </div>
        </div>
      )}
  
    </>
  );
}

interface PaywallStats {
  starts_count: number;
  views_count: number;
  views_unique_count: number;
  conversions_count: number;
  bounce_rate: number;
  conversion_rate: number;
}

interface Paywall {
  id: string;
  name: string;
  stats: PaywallStats;
}

function formatRate(rate: number | null | undefined): string {
  return typeof rate === 'number' ? `${rate.toFixed(1)}%` : '0.0%';
}

function formatCurrency(amount: number | null | undefined, currency: string): string {
  return typeof amount === 'number' ? `$${(amount/100).toFixed(2)} ${currency}` : '0.00';
}

function PaywallStatsTable({ workflow, queryString }) {
  const { data: paywalls, error } = useSWR<{ data: Paywall[] }>(
    `/convert/workflows/${workflow.id}/paywalls${queryString ? `${queryString}` : ''}`,
    getFetcher
  );

  if (error) return <div>Failed to load paywall stats</div>;
  if (!paywalls) return <div>Loading...</div>;

  // Calculate overall stats only if there's more than 1 paywall
  const showOverall = paywalls.data.length > 1;
  const overall = showOverall ? paywalls.data.reduce((acc, curr) => {
    return {
      unique_views: {
        value: (acc.unique_views?.value || 0) + (curr.stats?.unique_views?.value || 0)
      },
      views_count: {
        value: (acc.views_count?.value || 0) + (curr.stats?.views_count?.value || 0)
      },
      bounce_rate: {
        value: ((acc.bounce_rate?.value || 0) + (Number(curr.stats?.bounce_rate?.value?.replace('%', '')) || 0))
      },
      conversions_count: {
        value: (acc.conversions_count?.value || 0) + (curr.stats?.conversions_count?.value || 0)
      },
      conversion_rate: {
        value: ((acc.conversion_rate?.value || 0) + (Number(curr.stats?.conversion_rate?.value?.replace('%', '')) || 0))
      },
      revenue_sum: {
        value: ((acc.revenue_sum?.value || 0) + (Number(curr.stats?.revenue_sum?.value?.replace(/[^0-9.-]+/g, '')) || 0))
      }
    };
  }, {
    unique_views: { value: 0 },
    views_count: { value: 0 },
    bounce_rate: { value: 0 },
    conversions_count: { value: 0 },
    conversion_rate: { value: 0 },
    revenue_sum: { value: 0 }
  }) : null;

  // Calculate averages for rate-based metrics if showing overall
  if (showOverall && overall) {
    overall.bounce_rate.value = `${(overall.bounce_rate.value / paywalls.data.length).toFixed(1)}%`;
    overall.conversion_rate.value = `${(overall.conversion_rate.value / paywalls.data.length).toFixed(1)}%`;
    overall.revenue_sum.value = `$${overall.revenue_sum.value.toFixed(2)}`;
  }

  return (
    <div className="">
      <Table.Root variant="surface">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell className="border-r">Variant</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Unique Users</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Total Views</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Bounce Rate</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell className="border-r">Checkout Complete</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Conversion Rate</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Revenue</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {paywalls.data.map((paywall) => (
            <Table.Row key={paywall.id}>
              <Table.Cell className="border-r">
                <Link className="text-link" to={`/convert/paywalls/${paywall.id}/edit`}>
                  {paywall.name}
                </Link>
              </Table.Cell>
              <Table.Cell>{paywall.stats?.unique_views?.value || 0}</Table.Cell>
              <Table.Cell>{paywall.stats?.views_count?.value || 0}</Table.Cell>
              <Table.Cell>{paywall.stats?.bounce_rate?.value || '0%'}</Table.Cell>
              <Table.Cell className="border-r">{paywall.stats?.conversions_count?.value || 0}</Table.Cell>
              <Table.Cell>{paywall.stats?.conversion_rate?.value || '0%'}</Table.Cell>
              <Table.Cell>{paywall.stats?.revenue_sum?.value || '$0.00'}</Table.Cell> 
            </Table.Row>
          ))}
          {showOverall && overall && (
            <Table.Row className="font-bold">
              <Table.Cell className="border-r">Overall</Table.Cell>
              <Table.Cell>{overall.unique_views.value}</Table.Cell>
              <Table.Cell>{overall.views_count.value}</Table.Cell>
              <Table.Cell>{overall.bounce_rate.value}</Table.Cell>
              <Table.Cell className="border-r">{overall.conversions_count.value}</Table.Cell>
              <Table.Cell>{overall.conversion_rate.value}</Table.Cell>
              <Table.Cell>{overall.revenue_sum.value}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body> 
      </Table.Root>
    </div>
  );
}

export default function WorkflowDetailPage() {
  const params = useParams();
  const {data: workflow, error, mutate: mutateWorkflow} = useSWR(
    `/convert/workflows/${params.id}`, 
    getFetcher
  );

  if (error) return <div>Failed to load</div>;
  if (!workflow) return <div>Loading...</div>;

  return (
    <WorkflowLayout workflow={workflow}>
      <div className="space-y-4 px-10">
        <OverviewGridComponent workflow={workflow} />
        <PaywallStatsTable workflow={workflow} queryString={location.search} />
        <ConversionsListComponent workflow={workflow} />
      </div>
    </WorkflowLayout>
  );
}

function ConversionsListComponent({ workflow }) {
  function ConversionsListComponent() {
  const { data } = useSWR<Conversion[]>(`/convert/workflows/${workflow.id}/conversions`, getFetcher);

  if (!data) {
    return <div className="flex justify-center"><Skeleton />loading</div>;
  }

  return (
    <div className="space-y-4">

      <Heading size="4">Conversions</Heading>

      <div className="divide-y divide-gray-200 border-y border-gray-200 max-h-[220px] overflow-y-auto">
        {data.map((conversion) => (
          <div 
            key={conversion.id}
            className="flex items-center justify-between py-1"
          >
            <div className="flex items-center space-x-3">

              <Avatar 
                src={conversion.customer.avatar_url}
                fallback={avatarLetters(conversion.customer)}
                size={"1"}
              />

              <div className="flex flex-col">
                <div className="text-sm font-medium">{conversion.customer.email || conversion.customer.id}</div>
                <div className="text-xs text-gray-700 font-medium">
                  <time dateTime={conversion.created_at}>
                    {new Date(conversion.created_at).toLocaleDateString()} at{' '}
                    {new Date(conversion.created_at).toLocaleTimeString()}
                  </time>
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-semibold text-green-700 ">
                Upgrade
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
}