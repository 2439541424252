import { Heading, Flex, Select, Button, Skeleton, Table, Badge, Link, Avatar } from "@radix-ui/themes";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import cx from "classnames";
import { useLocalStorage } from '~/hooks/useLocalStorage';
import { avatarLetters } from "../customers/CustomersListPage";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function DateRangeFilter({ onFiltersChange }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [range, setRange] = useState('last_30_days');
  const [client, setClient] = useState('');
  const [paywall, setPaywall] = useState('');
  const [customRange, setCustomRange] = useState({ from: '', to: '' });
  const [storedFilters, setStoredFilters] = useLocalStorage('dashboard-filters', null);

  // Load clients and paywalls
  const { data: clients } = useSWR('/clients', getFetcher);
  const { data: paywalls } = useSWR('/convert/paywalls', getFetcher);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlRange = params.get('range');
    const urlClient = params.get('client');
    const urlPaywall = params.get('paywall');

    if (!urlRange && !urlClient && !urlPaywall && storedFilters) {
      // No URL params but we have stored filters - apply them silently
      const filters = {
        range: storedFilters.range || 'last_30_days',
        client: storedFilters.client || '',
        paywall: storedFilters.paywall || '',
        customRange: storedFilters.customRange || { from: '', to: '' }
      };
      updateFilters(filters.range, filters.client, filters.paywall, filters.customRange, false);
    } else {
      // Use URL params or defaults
      setRange(urlRange || 'last_30_days');
      setClient(urlClient || '');
      setPaywall(urlPaywall || '');
      if (urlRange === 'custom') {
        setCustomRange({
          from: params.get('from') || '',
          to: params.get('to') || ''
        });
      }
    }
  }, [location, storedFilters]);

  const updateFilters = (newRange, newClient, newPaywall, newCustomRange, updateStorage = true) => {
    const params = new URLSearchParams();
    params.set('range', newRange);
    if (newClient) params.set('client', newClient);
    if (newPaywall) params.set('paywall', newPaywall);
    if (newRange === 'custom') {
      params.set('from', newCustomRange.from);
      params.set('to', newCustomRange.to);
    }

    if (updateStorage) {
      setStoredFilters({
        range: newRange,
        client: newClient,
        paywall: newPaywall,
        customRange: newCustomRange
      });
    }

    navigate(`${location.pathname}?${params.toString()}`);
    onFiltersChange({ range: newRange, client: newClient, paywall: newPaywall, customRange: newCustomRange });
  };

  const handleReset = () => {
    setStoredFilters(null);
    updateFilters('last_30_days', '', '', { from: '', to: '' });
  };

  return (
    <Flex gap="3" align="center">
      <Select.Root value={range} onValueChange={(value) => updateFilters(value, client, paywall, customRange)}>
        <Select.Trigger />
        <Select.Content>
      
          <Select.Item value="today">Today</Select.Item>
          <Select.Item value="yesterday">Yesterday</Select.Item>
          <Select.Item value="last_7_days">Last 7 Days</Select.Item>
          <Select.Item value="last_30_days">Last 30 Days</Select.Item>
          <Select.Item value="last_365_days">Last 365 Days</Select.Item>
          <Select.Item value="this_month">This Month</Select.Item>
          <Select.Item value="last_month">Last Month</Select.Item>
          <Select.Item value="this_year">This Year</Select.Item>
          <Select.Item value="last_year">Last Year</Select.Item>
          <Select.Item value="all_time">All Time</Select.Item>
          <Select.Item value="custom">Custom Range</Select.Item>
        </Select.Content>
      </Select.Root>

      {range === 'custom' && (
        <Flex gap="2">
          <input
            type="date"
            value={customRange.from}
            onChange={(e) => setCustomRange({ ...customRange, from: e.target.value })}
          />
          <input
            type="date"
            value={customRange.to}
            onChange={(e) => setCustomRange({ ...customRange, to: e.target.value })}
          />
          <Button onClick={() => updateFilters(range, client, paywall, customRange)}>
            Apply
          </Button>
        </Flex>
      )}

      <Select.Root 
        value={client}
        onValueChange={(value) => updateFilters(range, value, paywall, customRange)}>
        <Select.Trigger placeholder="All Clients"/>
        <Select.Content>
          {/* <Select.Item value="all">All Clients</Select.Item> */}
          {clients?.data?.map((client) => (
            <Select.Item key={client.id} value={client.id.toString()}>
              {client.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>

      {/* <Select.Root 
        value={paywall}
        onValueChange={(value) => updateFilters(range, client, value, customRange)}>
        <Select.Trigger placeholder="All Paywalls"/>
        <Select.Content>
          <Select.Item value="">All Paywalls</Select.Item>
          {paywalls?.map((paywall) => (
            <Select.Item key={paywall.id} value={paywall.id.toString()}>
              {paywall.name}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root> */}

      <Button variant="outline" onClick={handleReset}>
        Reset
      </Button>
    </Flex>
  );
}
export function GlobalStatsComponent({ stats }) {

  const data = get(stats, 'data.stats', { });

  return (
    <div className="grid grid-cols-5 gap-4 bg-gray-950 text-white rounded-lg divide-x min-h-28 items-center divide-gray-900">
      <StatItem isLoading={stats.isLoading} label="Unique Views" stat={data?.unique_views} />
      <StatItem isLoading={stats.isLoading} label="Bounce rate" stat={data?.bounce_rate} />
      <StatItem isLoading={stats.isLoading} label="Conversions" stat={data?.conversions_count} />
      <StatItem isLoading={stats.isLoading} label="Conversion Rate" stat={data?.conversion_rate} />
      <StatItem isLoading={stats.isLoading} label="Total Revenue" stat={data?.revenue_sum} />
    </div>
  );
}

interface Stat {
  value: number;
  diff_percentage: number;
  diff_relative: string;
  negate?: boolean;
  formatter?: string;
}
interface StatItemProps {
  label: string;
  stat: any;
  isLoading: boolean;
}

function StatItem({ label, stat, isLoading }: StatItemProps) {
  if (isLoading) {
    return <div className="flex justify-center"><Skeleton /></div>;
  }
  if (!stat) {
    return null;
  }

  const formatValue = (value: number, formatter?: string) => {
    if (formatter === 'currency') {
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // notation: 'compact',
        // minimumFractionDigits: 0,
        // maximumFractionDigits: 1
      }).format(value / 100);
      
      // Convert K/M/B to lowercase
      return formatted; //.replace(/K|M|B/g, match => match.toLowerCase());
    }

    // For non-currency values, use compact notation
    const formatted = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1
    }).format(value);
    
    // Convert K/M/B to lowercase
    return formatted.replace(/K|M|B/g, match => match.toLowerCase());
  };

  return (
   <div className="flex h-full items-center justify-center">
     <div className="flex flex-col space-y-0.5 p-4">
      <div className="text-4xl font-medium leading-none">
        {stat.value}
      </div>
      <div className="text-sm tabular-nums">{label}</div>
      <div className="text-xs">
        {stat.diff_percentage !== null && (
          <>
            <span className={cx({
              "text-[#7EB500]": (!stat.negate && stat.diff_percentage > 0) || (stat.negate && stat.diff_percentage < 0),
              "text-red-500": (!stat.negate && stat.diff_percentage < 0) || (stat.negate && stat.diff_percentage > 0),
            })}>
              {stat.diff_percentage > 0 ? '+' : ''}{stat.diff_percentage}%
            </span>
            &nbsp;
            <span className="text-gray-500">
              {stat.diff_relative === 0 ? '-' : 
               `${stat.diff_relative > 0 ? '+' : ''}${formatValue(Math.round(stat.diff_relative), stat.formatter)} this period`}
            </span>
          </>
        )}
      </div>
    </div>
   </div>
  );
}

function OverviewGridComponent() {
  const location = useLocation();
  const [activeFilters, setActiveFilters] = useState(() => {
    // Initialize from URL params
    const params = new URLSearchParams(location.search);
    const range = params.get('range');
    const client = params.get('client');
    const paywall = params.get('paywall');
    
    // If custom range, get the date values
    const from = params.get('from') || '';
    const to = params.get('to') || '';

    // Check localStorage if no URL params
    const storedFilters = localStorage.getItem('dashboard-filters');
    const parsedStoredFilters = storedFilters ? JSON.parse(storedFilters) : null;

    // Use URL params if they exist, otherwise use stored filters, finally fall back to defaults
    return {
      range: range || (parsedStoredFilters?.range) || 'last_30_days',
      client: client || (parsedStoredFilters?.client) || '',
      paywall: paywall || (parsedStoredFilters?.paywall) || '',
      customRange: range === 'custom' 
        ? { from, to }
        : (parsedStoredFilters?.customRange || { from: '', to: '' })
    };
  });

  // Build query string for stats
  const queryString = new URLSearchParams({
    range: activeFilters.range,
    ...(activeFilters.client && { client: activeFilters.client }),
    ...(activeFilters.paywall && { paywall: activeFilters.paywall }),
    ...(activeFilters.range === 'custom' && {
      from: activeFilters.customRange.from,
      to: activeFilters.customRange.to
    })
  }).toString();

  const stats = useSWR(`/dashboard/stats?${queryString}`, getFetcher);

  // Listen for URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const range = params.get('range');
    const client = params.get('client');
    const paywall = params.get('paywall');
    
    if (range || client || paywall) {
      setActiveFilters(current => ({
        ...current,
        range: range || current.range,
        client: client || current.client,
        paywall: paywall || current.paywall,
        customRange: range === 'custom' 
          ? { 
              from: params.get('from') || current.customRange.from, 
              to: params.get('to') || current.customRange.to 
            }
          : current.customRange
      }));
    }
  }, [location.search]);

  return (
    <>
      <div className="flex gap-4">
        <DateRangeFilter 
          onFiltersChange={setActiveFilters}
        />
      </div>
      <GlobalStatsComponent stats={stats} />
    </>
  );
}

interface ActivityItem {
  date: string;
  views: number;
  starts: number;
  bounces: number;
  converts: number;
}

function ActivityComponent() { 
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientParam = params.get('client') ? `?client=${params.get('client')}` : '';

  const { data } = useSWR(`/dashboard/activity${clientParam}`, getFetcher);
  const activity = data || [];

  const chartData = {
    labels: activity.map(item => new Date(item.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Views',
        data: activity.map(item => item.views),
        backgroundColor: '#49B7D1',
        stack: 'stack1',
        barPercentage: 1,
        categoryPercentage: 0.8,
      },
      {
        label: 'Starts',
        data: activity.map(item => item.starts),
        backgroundColor: '#A1DCEB', 
        stack: 'stack1',
        barPercentage: 1,
        categoryPercentage: 0.8,
      },
      {
        label: 'Conversions',
        data: activity.map(item => item.converts),
        backgroundColor: '#7eb5007a', 
        stack: 'stack1',
        barPercentage: 1,
        categoryPercentage: 0.8,
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        display: false,
        offset: true,
        grid: {
          offset: false
        }
      },
      y: {
        stacked: true,
        display: false,
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: (context) => {
            return new Date(activity[context[0].dataIndex].date).toLocaleDateString();
          },
          label: (context) => {
            const label = context.dataset.label;
            const value = context.raw;
            return `${label}: ${value}`;
          }
        }
      }
    },
  };

  if (!activity?.length) {
    return <div>No activity data available</div>;
  }

  return (
    <div className="h-full flex flex-col">
      <Heading size="4">Activity</Heading>
      <div className="flex-1 flex bg-red-X100 flex-end max-h-[230px]">
        <Bar options={options} data={chartData} />
      </div>
    </div>
  );
}

interface RevenueItem {
  date: string;
  amount: number;
}
interface RevenueResponse {
  current: RevenueItem[];
  previous: RevenueItem[];
  totals: {
    current: number;
    difference: number;
    difference_percentage: number;
  }
}
function RevenueTrendComponent() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientParam = params.get('client') ? `?client=${params.get('client')}` : '';

  const { data } = useSWR<RevenueResponse>(`/dashboard/revenue${clientParam}`, getFetcher);

  if (!data) {
    return <div className="flex justify-center"><Skeleton /></div>;
  }

  // Calculate the maximum value from both current and previous datasets
  // Convert cents to dollars by dividing by 100
  const maxAmount = Math.max(
    ...data.current.map(item => item.amount / 100),
    ...data.previous.map(item => item.amount / 100)
  );

  // Function to get nice rounded max value and step size
  const getNiceScale = (max: number) => {
    // Ensure minimum of $100
    if (max < 100) max = 100;

    // Get the magnitude (10^n) just above the max value
    const magnitude = Math.pow(10, Math.floor(Math.log10(max)));
    
    // Round up to a nice number
    let niceMax = Math.ceil(max / magnitude) * magnitude;

    // If the nice number is more than 2x the max, divide by 2
    if (niceMax > max * 2) {
      niceMax = niceMax / 2;
    }

    return {
      max: niceMax,
      step: niceMax / 2
    };
  };

  const scale = getNiceScale(maxAmount);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: value >= 1000000 ? 'compact' : 'standard',
      maximumFractionDigits: value >= 1000000 ? 1 : 0
    }).format(value);
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
        border: {
          display: false,
        }
      },
      y: {
        display: true,
        min: 0,
        max: scale.max,
        grid: {
          color: '#E2E7FF',
          drawBorder: false,
          drawTicks: false,
          lineWidth: 1,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          count: 3,
          callback: (value: number) => formatCurrency(value),
          padding: 10,
          align: 'end',
          color: '#6B7280',
          font: {
            size: 12,
          }
        },
        border: {
          display: false,
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: (context) => {
            return new Date(data.current[context[0].dataIndex].date).toLocaleDateString();
          },
          label: (context) => {
            const label = context.dataset.label;
            return `${label}: ${formatCurrency(context.raw as number)}`;
          }
        }
      }
    },
  };

  const chartData = {
    labels: data.current.map(item => new Date(item.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Current Period',
        data: data.current.map(item => item.amount / 100), // Convert cents to dollars
        borderColor: '#008DA7',
        backgroundColor: '#008DA7',
        fill: false,
        tension: 0,
        pointRadius: 0,
        borderWidth: 2,
      },
      {
        label: 'Previous Period',
        data: data.previous.map(item => item.amount / 100), // Convert cents to dollars
        borderColor: '#E2E7FF',
        backgroundColor: '#E2E7FF',
        fill: false,
        tension: 0,
        pointRadius: 0,
        borderWidth: 2,
      }
    ]
  };

  return (
    <div className="space-y-2">
      <Heading size="4">New Revenue</Heading>
      <div className="flex justify-between items-center">
        <div className="inline-flex text-baseline gap-2">
          <div className="text-lg font-medium">
            {formatCurrency(data.totals.current / 100)} {/* Convert cents to dollars */}
          </div>
          
          <div className="text-sm text-[#7EB500] font-semibold flex items-center align-baseline">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 14 16" fill="none"><path d="M3.59424 9.59999H9.88409L6.73917 4.79999L3.59424 9.59999Z" fill="currentColor"/></svg>
            {formatCurrency(data.totals.difference / 100)} {/* Convert cents to dollars */}
          </div>
          <div className="font-medium">vs previous period</div>
        </div>
      </div>
      <Line options={options} data={chartData} />
    </div>
  );
}

export interface Conversion {
  id: string;
  created_at: string;
  customer: {
    email: string;
    avatar_url: string;
  };
  paywall: {
    name: string;
  };
}

function ConversionsListComponent() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientParam = params.get('client') ? `?client=${params.get('client')}` : '';

  const { data } = useSWR<Conversion[]>(`/dashboard/conversions${clientParam}`, getFetcher);

  if (!data) {
    return <div className="flex justify-center"><Skeleton /></div>;
  }

  return (
    <div className="space-y-4">

      <Heading size="4">Conversions</Heading>

      <div className="divide-y divide-gray-200 border-y border-gray-200 max-h-[220px] overflow-y-auto">
        {data.map((conversion) => (
          <div 
            key={conversion.id}
            className="flex items-center justify-between py-1"
          >
            <div className="flex items-center space-x-3">

              {conversion.customer && (
                <Avatar 
                  src={conversion.customer?.avatar_url}
                  fallback={avatarLetters(conversion.customer)}
                  size={"1"}
                />
              )}
              <div className="flex flex-col">
                <div className="text-sm font-medium">{conversion.customer?.email || conversion.customer?.id}</div>
                <div className="text-xs text-gray-700 font-medium">
                  <time dateTime={conversion.created_at}>
                    {new Date(conversion.created_at).toLocaleDateString()} at{' '}
                    {new Date(conversion.created_at).toLocaleTimeString()}
                  </time>
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-semibold text-green-700 ">
                Upgrade
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

interface ActivityEvent {
  id: string;
  created_at: string;
  customer: {
    email: string;
    avatar_url: string;
  };
  trigger: string;
  paywall: {
    id: string;
    name: string;
  };
  latest_event: {
    id: number;
    name: string;
    created_at: string;
  };
}

function RecentActivityComponent() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientParam = params.get('client') ? `?client=${params.get('client')}` : '';

  const { data, error, isLoading } = useSWR<ActivityEvent[]>(`/dashboard/activities${clientParam}`, getFetcher);

  if (error) {
    return (
      <div className="rounded-lg border border-red-200 bg-red-50 p-4">
        <div className="text-sm text-red-600">
          Error loading recent activity: {error.message}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Table.Root variant="surface">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>User Info</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Trigger</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Variant</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Date</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Event</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {[...Array(5)].map((_, i) => (
            <Table.Row key={i}>
              <Table.Cell>
                <Flex align="center" gap="2">
                  <Skeleton className="w-8 h-8 rounded-full" />
                  <Skeleton className="w-32 h-4" />
                </Flex>
              </Table.Cell>
              <Table.Cell><Skeleton className="w-24 h-4" /></Table.Cell>
              <Table.Cell><Skeleton className="w-32 h-4" /></Table.Cell>
              <Table.Cell><Skeleton className="w-40 h-4" /></Table.Cell>
              <Table.Cell><Skeleton className="w-20 h-6 rounded-full" /></Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    );
  }

  if (!data?.length) {
    return (
      <div className="rounded-lg border border-gray-200 bg-gray-50 p-4">
        <div className="text-sm text-gray-600">
          No recent activity found
        </div>
      </div>
    );
  }

  return (
    <Table.Root variant="surface">
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell>User Info</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Trigger</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Variant</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Date</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Event</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map((activity) => (
          <Table.Row key={activity.id}>
            <Table.Cell>
              <Flex align="center" gap="2">

                {activity.customer && (
                  <Avatar 
                    src={activity.customer?.avatar_url}
                    fallback={avatarLetters(activity.customer)}
                    size={"1"}
                  />
                )}

                <div className="font-semibold">{activity.customer?.email || activity.customer?.id}</div>
              </Flex>
            </Table.Cell>
            <Table.Cell>{activity.trigger}</Table.Cell>
            <Table.Cell>
              <Link href={`/paywalls/${activity.paywall.id}`} className="text-teal-800 hover:underline">
                {activity.paywall.name}
              </Link>
            </Table.Cell>
            <Table.Cell>
              <div className="">
                {new Date(activity.created_at).toLocaleDateString()} at{' '}
                {new Date(activity.created_at).toLocaleTimeString()}
              </div>
            </Table.Cell>
            <Table.Cell>
              <Badge color={activity.latest_event?.name === 'converted' ? 'green' : 'gray'}>
                {activity.latest_event?.name || 'unknown'}
              </Badge>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
}

export default function ConvertHomePage() {
  return (
    <div className="p-10 space-y-4">
      <Heading>Your Overview</Heading>

      <OverviewGridComponent/>

      <div className="py-3 px-1">
      <hr />
      </div>

      <div className="grid grid-cols-3 gap-8 px-4 pb-4">
        <div className=""> 
          <RevenueTrendComponent/>
        </div>
        <div className=""> 
          <ActivityComponent/>
        </div>
        <div className=""> 
          <ConversionsListComponent/>
        </div>
      </div>

      <div className="px-4 pb-4 space-y-2 ">
        <Heading size="4">Recent Activity</Heading>
        <RecentActivityComponent/>
      </div>
    </div>
  )
}
